import Vue from 'vue';

import Vuex, {StoreOptions} from 'vuex';

import {RootState} from './RootState';

import {factory} from '@/logging';
import {homeImage} from './homeImage';

const logger = factory.getLogger('vuex.store');

Vue.use(Vuex);

export default new Vuex.Store<RootState>({
  state:     { },
  mutations: { },
  getters:   { },
  actions:   { },
  plugins:   [ ],
  modules: {
    homeImage,
  },
});
