import {
  ActionContext,
  Module
} from 'vuex';

import { RootState        } from '@/store/RootState';
import { factory          } from '@/logging';
import { AzureStorageBlob } from '@wwinc/azure-storage';

import vue from 'vue';

/**
 * The Division state
 */
export interface IHomeImageState {
  rectImages:         AzureStorageBlob[ ];
  sqrImages:          AzureStorageBlob[ ];
  wideImages:         AzureStorageBlob[ ];
  regImages:          AzureStorageBlob[ ];
  selectedRectImages: AzureStorageBlob[ ];
  selectedSqrImages:  AzureStorageBlob[ ];
  selectedWideImages: AzureStorageBlob[ ];
  selectedRegImages:  AzureStorageBlob[ ];
  loading:            boolean;
}

const logger = factory.getLogger('vuex.modules.homeImage');

interface WideImagesParams {
  state: IHomeImageState;
}

/**
 * Represents the state of the homeImage part of the store.
 */
export const homeImage: Module<IHomeImageState, RootState> = {
  namespaced: true,
  state: {
    rectImages:         [ ] as AzureStorageBlob[ ],
    sqrImages:          [ ] as AzureStorageBlob[ ],
    wideImages:         [ ] as AzureStorageBlob[ ],
    regImages:          [ ] as AzureStorageBlob[ ],
    selectedRectImages: [ ] as AzureStorageBlob[ ],
    selectedSqrImages:  [ ] as AzureStorageBlob[ ],
    selectedWideImages: [ ] as AzureStorageBlob[ ],
    selectedRegImages:  [ ] as AzureStorageBlob[ ],
    loading:            true
  },
  getters: {
    loading:            (state: IHomeImageState) => state.loading,
    rectImages:         (state: IHomeImageState) => state.rectImages,
    sqrImages:          (state: IHomeImageState) => state.sqrImages,
    wideImages:         (state: IHomeImageState) => state.wideImages,
    regImages:          (state: IHomeImageState) => state.regImages,
    selectedRectImages: (state: IHomeImageState) => state.selectedRectImages,
    selectedSqrImages:  (state: IHomeImageState) => state.selectedSqrImages,
    selectedWideImages: (state: IHomeImageState) => state.selectedWideImages,
    selectedRegImages:  (state: IHomeImageState) => state.selectedRegImages,
  },
  mutations: {
    rectImages: (
      state:      IHomeImageState,
      rectImages: AzureStorageBlob[]
    ) => {
      state.rectImages = rectImages;
    },
    sqrImages: (
      state:     IHomeImageState,
      sqrImages: AzureStorageBlob[]
     ) => {
      state.sqrImages = sqrImages;
    },
    wideImages: (
      state:      IHomeImageState,
      wideImages: AzureStorageBlob[]
    ) => {
      state.wideImages = wideImages;
    },
    regImages: (
      state:     IHomeImageState,
      regImages: AzureStorageBlob[]
    ) => {
      state.regImages = regImages;
    },
    selectedRectImages: (
      state:  IHomeImageState,
      images: AzureStorageBlob[]
    ) =>{
      state.selectedRectImages = images;
    },
    selectedSqrImages: (
      state:  IHomeImageState,
      images: AzureStorageBlob[]
    ) =>{
      state.selectedSqrImages = images;
    },
    selectedWideImages: (
      state:  IHomeImageState,
      images: AzureStorageBlob[]
    ) =>{
      state.selectedWideImages = images;
    },
    selectedRegImages: (
      state:  IHomeImageState,
      images: AzureStorageBlob[]
    ) =>{
      state.selectedRegImages = images;
    },
    loading: (
      state:   IHomeImageState,
      loading: boolean
    ) => {
      state.loading = loading;
    }
  },
  actions: {
    /**
     * Changes the random images.
     * @param context The VueX context
     */
    switchImages(context: ActionContext<IHomeImageState, RootState>) {
        logger.debug(`Is Loading = ${context.getters.loading}`);
        if(!context.getters.loading){
          context.commit(
            'selectedRectImages',
            (vue as any)
              .$imageService
              .selectDifferentImages(
                context.state.rectImages,
                1
              )
          );
          context.commit(
            'selectedSqrImages',
            (vue as any)
              .$imageService
              .selectDifferentImages(
                context.state.sqrImages,
                2
              )
          );
          context.commit(
            'selectedWideImages',
            (vue as any)
              .$imageService
              .selectDifferentImages(
                context.state.wideImages,
                2
              )
          );
          context.commit(
            'selectedRegImages',
            (vue as any)
              .$imageService
              .selectDifferentImages(
                context.state.regImages,
                1
              )
          );
        }

    },
    /**
     * Loads front page images.
     * @param context The VueX context
     */
     loadImages(context: ActionContext<IHomeImageState, RootState>) {
        logger.debug('Inside of all method');
        logger.debug('Loading front page images');

        // returns array of retangle
       (vue as any).$imageService.getImages('aspect-images-home-468x634').subscribe((rectangle: any)=>{
          logger.debug(`Loaded: ${rectangle.length} X 468x634 home images`);
          context.commit('rectImages', rectangle);

          // returns array of square blobs
         (vue as any).$imageService.getImages('aspect-images-home-466x466').subscribe((square: any) => {
            logger.debug(`Loaded ${square.length} X 466x466 home images`);
            context.commit('sqrImages', square);

            // returns array of wide
           (vue as any).$imageService.getImages(`aspect-images-home-960x466`).subscribe((wide: any) => {
              logger.debug(`Loaded ${wide.length} X 960x466 home images`);
              context.commit('wideImages', wide);

              // returns array of regular
              (vue as any).$imageService.getImages('aspect-images-home-468x318').subscribe((regular: any) => {
                logger.debug(`Loaded ${regular.length} X 468x318 home images`);
                context.commit('regImages', regular);

                context.commit('selectedRectImages', rectangle);
                context.commit('selectedSqrImages',  square);
                context.commit('selectedWideImages', wide);
                context.commit('selectedRegImages',  regular);
                context.commit('loading',            false);
              });
            });
          });
        });
    }
  },
};
