import {
  AzureStorage,
  AzureStorageBlob, ProductionSecureConfig
} from '@wwinc/azure-storage';

import {
  Observable,
  of
} from 'rxjs';

import {
  catchError, map
} from 'rxjs/operators';

import Axios from 'axios-observable';
import X2JS  from  'x2js';

import {
  factory
} from '@/logging';

import {
  DevSecureConfig
} from '@wwinc/azure-storage/app.config';
import AppConfig from '@/AppConfig';

export interface Image {
  id:  number;
  src: string;
  alt: string;
}

const logger = factory.getLogger('service.image');

export class ImageService {

  private azureStorage: AzureStorage;

  constructor(private config: AppConfig) {
    logger.debug('Instantiating Image Service');

    if(process.env.VUE_APP_MODE === 'Development') {
      AzureStorage.forRoot(DevSecureConfig);
    } else {
      AzureStorage.forRoot(ProductionSecureConfig);
    }

    AzureStorage.config.containersURL =  config.containersUrl;

    this.azureStorage = new AzureStorage();
   }

  /**
   * Gets the images from Azure Storage by container name.
   * @param containerName The name of the container containing the images.
   */
  getImages(containerName: any): Observable<AzureStorageBlob[]> {
    const url = `${AzureStorage.config.storageAccountUrl}${containerName}?restype=container&comp=list&include=metadata`;
    const ref = this;
    const x2js = new X2JS();

    return Axios.get(
      `${AzureStorage.config.storageAccountUrl}${containerName}?restype=container&comp=list&include=metadata`,
      { responseType: 'text' }
      ).pipe(map((response) => {
        // first we have to translate microsoft's xml response to JSON
        let converted = <any>{};
        converted = x2js.xml2js(response.data);
        // update the images now
        const images = converted.EnumerationResults.Blobs.Blob;
        if (images != null && images.length) {
          const imagesList = Object.keys(images).map((dataIndex) => {
            return images[dataIndex];
        });
          return imagesList;
        }
        else if (images == null) {
          return [];
        }
        else {
          return [images];
        }
    }))
      .pipe(
        map((images: AzureStorageBlob[]) => {
          images.forEach((image: AzureStorageBlob) => {
            if (image.Metadata) {
              ref.parseAzureMetadata(image.Metadata);
            }
          });
          return images;
        }),
        catchError((val) => {
          return of([ ]);
        }),
      );
  }
  /**
   * Updates an Azure storage blob to
   * have actual boolean values, instead of string
   * representations.
   *
   * @param obj The blob to update.
   */
  parseAzureMetadata(obj: any) {
    Object.keys(obj).forEach((key) => {
      if (key === 'type') {
        obj[key] = +obj[key];
      }
      else if (
        obj[key] === 'False' ||
        obj[key] === 'false' ||
        obj[key] === 'True' ||
        obj[key] === 'true') {
        const s: string = obj[key];
        delete obj[key];
        obj[key] = this.stringToBoolean(s);
        }
    });
    return obj;
  }

  /**
   * Converts a string with a boolean to an actual boolean value
   *
   * @param val The boolean value in string form.
   * @private
   */
  private stringToBoolean(val: string): boolean {
    const a: any = {
      true:  true,
      True:  true,
      false: false,
      False: false,
    };
    return a[val];
  }

  /**
   * Selects random images.
   *
   * @param imagesInFolder The collection of images.
   * @param randNeeded     The number of random images needed.
   */
  selectDifferentImages(imagesInFolder: AzureStorageBlob[], randNeeded: number) {
    logger.debug('Inside imageService.selectDifferentImages');
    logger.debug(`imagesInFolder.length = ${imagesInFolder.length}`);
    if (imagesInFolder.length === 0 ) {
      return null;
    }

    const num = imagesInFolder.length;

    const theChosenOnes = [];

    //pick random selections
    for (let i = 0; i < num; i++) {
      const  rand = Math.floor(Math.random() * num);
      if (theChosenOnes.indexOf(imagesInFolder[rand]) == -1) {

        logger.debug('Adding image to chosen images');
        // if it doesnt currently exist in the array, we want to push it :)
        // hahaha
        theChosenOnes.push(imagesInFolder[rand]);
      }
      else {
        logger.debug('Random image was already in the array');
        //number already in array, pick new random number
        i = i - 1;
      }
    }
    return theChosenOnes;
  }

}
