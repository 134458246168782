import Vue          from 'vue';
import Home         from './components/home.vue';
import About        from './components/about.vue';
import Products     from './components/products.vue';
import Rangehoods   from './components/products/rangehoods.vue';
import WoodTypes    from './components/products/woodtypes.vue';
import DoorStyles   from './components/products/doorstyles.vue';
import Finishes     from './components/products/finishes.vue';
import Paint        from './components/products/finishes/paint.vue';
import Stain        from './components/products/finishes/stain.vue';
import Gallery      from './components/gallery.vue';
import Retailers    from './components/retailers.vue';
import Resources    from './components/resources.vue';
import Privacy      from './components/privacy.vue';
import FourZeroFour from './components/page404.vue';
import LightBoxTest from './components/lightboxtest.vue';
import
  Router,
  { RouterOptions }
from 'vue-router';

Vue.use(Router);
const suffix = '| Aspect Cabinetry';

export default new Router(
  <RouterOptions> {
    mode: 'history',
    routes: [
      {
        path: '/',
        component: Home,
        meta: {
          title: 'Aspect Cabinetry'
        }
      },
      // {
      //   path: '/',
      //   component: LightBoxTest,
      //   meta: {
      //     title: 'Light Box Test'
      //   }
      // },
      {
        path: '/about',
        component: About ,
        meta: {
          title: `About ${suffix}`
        }
      },
      {
        path: '/products',
        component: Products,
        meta: {
          title: `Products ${suffix}`
        }
      },
      {
        path: '/products/rangehoods',
        component: Rangehoods,
        meta: {
          title: `Range Hoods ${suffix}`
        }
      },
      {
        path: '/products/woodtypes',
        component: WoodTypes,
        meta: {
          title: `Wood Types ${suffix}`
        }
      },
      {
        path: '/products/doorstyles',
        component: DoorStyles ,
        meta: {
          title: `Door Styles ${suffix}`
        }
      },
      {
        path: '/products/finishes',
        component: Finishes,
        meta: {
          title: `Finishes ${suffix}`
        }
      },
      {
        path: '/products/finishes/paint',
        component: Paint,
        meta: {
          title: `Paint ${suffix}`
        }
      },
      {
        path: '/products/finishes/stain',
        component: Stain ,
        meta: {
          title: `Stain ${suffix}`
        }
      },
      {
        path: '/gallery',
        component: Gallery,
        meta: {
          title: `Gallery ${suffix}`
        }
      },
      {
        path: '/retailers',
        component: Retailers ,
        meta: {
          title: `Retailers ${suffix}`
        }
      },
      {
        path: '/resources',
        component: Resources,
        meta: {
          title: `Resources ${suffix}`
        }
      },
      {
        path: '/privacy',
        component: Privacy,
        meta: {
          title: `Privacy Policy ${suffix}`
        }
      },
      {
        path: '*',
        component: FourZeroFour,
        meta: {
          title: `404 - Page Not Found ${suffix}`
        }
      },

      { path: '/gallery.html',           redirect: '/gallery'                 },
      { path: '/index.html',             redirect: '/'                        },
      { path: '/gallery.html',           redirect: '/gallery'                 },
      { path: '/locate-a-retailer.html', redirect: '/retailers'               },
      { path: '/become-a-retailer.html', redirect: '/retailers'               },
      { path: '/products.html',          redirect: '/products'                },
      { path: '/Products.html',          redirect: '/products'                },
      { path: '/about.html',             redirect: '/about'                   },
      { path: '/About.html',             redirect: '/about'                   },
      { path: '/downloads',              redirect: '/resources'               },
      { path: '/paint.html',             redirect: '/products/finishes/paint' },
      { path: '/Paint.html',             redirect: '/products/finishes/paint' },
      { path: '/finishes.html',          redirect: '/products/finishes'       },
      { path: '/Finishes.html',          redirect: '/products/finshes'        },
      { path: '/doorstyles.html',        redirect: '/products/doorstyles'     },
    ],
  },
);
