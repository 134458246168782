














































































import Vue from 'vue';

import { factory } from '@/logging';


const logger = factory.getLogger('component.navbar');

export default Vue.extend({
  name:       'navbar',
  props:      {
    section: String
  },
  components: {},
  created(): void {
    logger.trace('Navbar component has been created.');
  },
  methods:  {},
  computed: {
    isHome() {
      return this.section === 'home';
    },
    isAbout() {
      return this.section === 'about';
    },
    isProducts() {
      return this.section === 'products';
    },
    isGallery() {
      return this.section === 'gallery';
    },
    isRetailers() {
      return this.section === 'retailers';
    },
    isResources() {
      return this.section === 'resources';
    }
  },
  data() {
    return {};
  }
});

