










import Vue from 'vue';

import { factory } from '@/logging';

import Footer from "@/components/pagefooter.vue";

import * as TSL    from 'typescript-logging';
const logger = factory.getLogger('component.app');

export default Vue.extend({
  name:       'app',
  props:      { },
  components: { Footer },
  methods:    { },
  computed:   { },
  data() {
    return {
      mode: process.env.VUE_APP_MODE
    };
  },
  created() {
    const control = TSL.getLogControl();

    const factoryControl = control.getLoggerFactoryControl(0);

    let logLevel: "Fatal" | "Error" | "Warn" | "Info" | "Debug" | "Trace" | undefined  = "Fatal";

    if(process.env.VUE_APP_MODE === 'Development') {
      logLevel = "Trace";
    }

    factoryControl.change({
      logFormat:      undefined,
      showLoggerName: undefined,
      showTimestamp:  undefined,
      group:          'all',
      logLevel
    });
  }
});

// We finna fade in these images
Vue.directive('animateOnChange', {

  update: (element: Element, binding, vnode) => {
    element.classList.add('fade-in');

    setTimeout(function() {
      element.classList.remove('fade-in');
    }, 1000);

  }
});

