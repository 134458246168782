




















































































































import Vue                  from 'vue';
import vue                  from 'vue';
import PageHeader           from '@/components/products/pageheader.vue';
import { AzureStorageBlob } from "@wwinc/azure-storage";

// Enum that contains all the possible paint option menus
enum PaintOption {
  Paint   = "Paint on Maple",
  RedOak  = "Paint on Red Oak"
}

interface Data {
  PaintOption:         any,
  mapleText:           string,
  redOakText:          string,
  selectedPaint:       PaintOption,
  selectedImage:       AzureStorageBlob | null,
  paints:              AzureStorageBlob[],
  redOakPaints:        AzureStorageBlob[]
}

export default Vue.extend({
  name: 'Paint',

  components: {
    PageHeader,
  },

  inject: [
    '$imageService'
  ],

  props: {
    section: String
  },

  data(): Data {
    return {
      PaintOption, // Added to allow access to PaintOption in HTML Template
      mapleText: "*Painted Maple will be maple, and will contain varied MDF components depending on product selection. " +
          "Door panels for painted finishes will be MDF, with hardwood rails.\n" +
          "Painted finishes will show evidence of slight crack lines at joints in doors & frames. " +
          "Areas of end grains on edges and panel cuts may exhibit voids in wood which appear as rough spots. " +
          "We consider these imperfections normal and not reason for replacement.",
      redOakText: "*Painted Red Oak will have solid oak rails and center panels on raised panel doors. " +
          "For flat panel doors, the rails will be solid oak and the center panel will be oak veneer on composite core. " +
          "Painted finishes will show evidence of slight crack lines at joints in doors & frames. " +
          "Areas of end grains on edges and panel cuts may exhibit voids in wood which appear as rough spots. " +
          "We consider these imperfections normal and not reason for replacement.",
      selectedPaint:       PaintOption.Paint,
      selectedImage:       null,
      paints:              [],
      redOakPaints:        []
    }
  },

  methods: {
    // Returns an array of paints for the specific paint option
    // @param paintOption - A PaintOption enum value
    imagesToShow(paintOption: PaintOption): AzureStorageBlob[] {
      switch (paintOption) {
        case PaintOption.Paint:   return this.paints
        case PaintOption.RedOak:  return this.redOakPaints
        default:
          console.error("DEFAULT CASE SHOULDN'T BE TRIGGERED");
          return this.paints;
      }
    },

    // changes the selectedImage to what was clicked on
    changeSelectedImage (image: AzureStorageBlob | null) {
      if (image != null) {
        this.selectedImage = image;
      }
    },

    // Change the paints when a new option is selected.
    // @param newOption - A paintOption enum value
    changeSelected(newOption: PaintOption) {
      this.selectedPaint = newOption;

      switch (newOption) {
        case PaintOption.Paint:
          this.selectedImage = this.paints[0];
          break;

        case PaintOption.RedOak:
          this.selectedImage = this.redOakPaints[0];
          break;

        default:
          console.error("DEFAULT CASE SHOULDN'T BE TRIGGERED");
          break;
      }
    }
  },

  beforeMount() {
    // Cleans all the metadata to ensure none of the values are undefined/null
    const cleanMetadata = (image: AzureStorageBlob) => {
      if (image.Metadata == undefined || image.Metadata == "") image.Metadata = {};

      if (image.Url                   == undefined) image.Url                   = "";
      if (image.Name                  == undefined) image.Name                  = "";
      if (image.Metadata.finish       == undefined) image.Metadata.finish       = "None";
      if (image.Metadata.finish_type  == undefined) image.Metadata.finish_type  = "None";
      if (image.Metadata.cabinet      == undefined) image.Metadata.cabinet      = "None";
      if (image.Metadata.door         == undefined) image.Metadata.door         = "None";
      if (image.Metadata.glaze        == undefined) image.Metadata.glaze        = "None";
      if (image.Metadata.html         == undefined) image.Metadata.html         = "";
      if (image.Metadata.other        == undefined) image.Metadata.other        = "None";
      if (image.Metadata.aged         == undefined) image.Metadata.aged         = false;
      if (image.Metadata.species      == undefined) image.Metadata.species      = "None";
      if (image.Metadata.shade        == undefined) image.Metadata.shade        = "None";
      if (image.Metadata.thumbnail    == undefined) image.Metadata.thumbnail    = "None";
      if (image.Metadata.type         == undefined) image.Metadata.type         = 0;
      if (image.Metadata.display_name == undefined) image.Metadata.display_name = "";
      if (image.Metadata.upload_date  == undefined) image.Metadata.upload_date  = "None";
    }

    // alphabetically sorts an array of AzureStorageBlobs (images) by finish name
    const imageAlphabeticalSort =
      (imageA: AzureStorageBlob, imageB: AzureStorageBlob) =>
        imageA.Metadata.finish.localeCompare(imageB.Metadata.finish);

    // Fills this.paints with all the paint options
    (vue as any)
      .$imageService
      .getImages('aspect-products-finishes-paint')
      .subscribe( (images: AzureStorageBlob[]) => {
        images.map(cleanMetadata);

        const maple = images.filter( (image: AzureStorageBlob) =>
          image.Metadata         !=   undefined &&
          image.Metadata.finish  !=   undefined &&
          image.Metadata.finish  !== 'None'     &&
          image.Metadata.species === 'Maple'
        );

        maple.sort(imageAlphabeticalSort);
        this.paints = maple;
        this.changeSelectedImage(maple[0]); // Sets the default selected paint
      });

    // sets this.redOakPaints with red oak paint images
    (vue as any)
      .$imageService
      .getImages('aspect-products-finishes-paint')
      .subscribe( (images: AzureStorageBlob[]) => {
        images.map(cleanMetadata);

        const oakPaints = images.filter( (image: AzureStorageBlob) =>
          image.Metadata         !=  undefined &&
          image.Metadata.finish  !=  undefined &&
          image.Metadata.finish  !== 'None'    &&
          image.Metadata.species === 'Red Oak'
        );

        oakPaints.sort(imageAlphabeticalSort);
        this.redOakPaints = oakPaints;
      });
  },
});
