import { factory } from '@/logging';
import 'rxjs/add/operator/map';

import {
    AzureStorage,
    AzureStorageBlob, ProductionSecureConfig
} from '@wwinc/azure-storage';

import {
  DevSecureConfig
} from '@wwinc/azure-storage/app.config';

import {
  Observable
} from 'rxjs';

import {
  map
} from 'rxjs/operators';

import Axios from 'axios-observable';
import X2JS from 'x2js';
import AppConfig from '@/AppConfig';
import Resource from "@/models/resource.model";

const logger = factory.getLogger('service.resource');

export class ResourcesService {

  private azureStorage: AzureStorage;

  constructor(private config: AppConfig) {
    logger.debug('Instantiating Resources service');

    if(process.env.VUE_APP_MODE === 'Development') {
      console.log("Using the DevSecure AzureStorage configuration");
      AzureStorage.forRoot(DevSecureConfig);
    } else {
      console.log("Using the ProductionSecure AzureStorage configuration");
      AzureStorage.forRoot(ProductionSecureConfig);
    }

    console.log(`Setting the containersURL to ${config.containersUrl}`);
    AzureStorage.config.containersURL =  config.containersUrl;

    this.azureStorage = new AzureStorage();
  }

  getResources(): Observable<Resource[]> {
      return this.getBlobs(
          'aspect-documents-resources'
      ).pipe(map(
          (data: AzureStorageBlob[])=> {
              let downloads: Resource[] = [] as Resource[];

              downloads = data.map((blob:any) => {
                  const name = blob.Metadata.name && blob.Metadata.name !== 'None' ? blob.Metadata.name : '';
                  let description = '';

                  if(blob.Metadata.description && blob.Metadata.description !== 'None') {
                      description = blob.Metadata.description;
                  } else if (blob.Metadata.other && blob.Metadata.other !== 'None') {
                      description = blob.Metadata.other;
                  }

                  return new Resource(
                      blob.id,
                      name,
                      description,
                      blob.Url
                  )
              })

              return downloads;
          })
      );
  }
    /**
     * Loads blobs from a storage container
     * @param azureStorageContainer The storage container
     */
  private getBlobs(azureStorageContainer: any): Observable<AzureStorageBlob[]> {
    return Axios.get(
        `${AzureStorage.config.storageAccountUrl}${azureStorageContainer}?restype=container&comp=list&include=metadata`,
      {
        responseType: 'text'
      }
    )
    .pipe(map((response) => {
        const x2js = new X2JS();
        let   json = <any>{};

        json = x2js.xml2js(response.data);

        return json.EnumerationResults.Blobs.Blob;
    }));
  }
}
