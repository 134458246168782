<template>
  <footer class="footer">
    <div class="container">
      <div class="row d-flex justify-content-end">
        <div class="col-12 col-md-9 text-md-left">
          <p id="disclaimer">
            *Internet technology does not allow for completely accurate
            reproduction of wood, stain or paint colors. Visit an independent
            retailer to view displays and samples for actual colors.
            W. W. Wood Products, Inc. does not guarantee that all information
            on this site is accurate. Although every attempt is made to
            ensure the information on this site is kept current and accurate,
            specifications can change without notice. 
            <router-link to="/privacy">Privacy Policy</router-link>
          </p>
        </div>
        <div class="col-3 col-md-1 text-center youtube">
          <a
              href   = "https://www.youtube.com/channel/UCleWLfPJrVxMYYR_eD357vQ"
              target = "_blank"
          >
              <span class="material-icons">
                  ondemand_video
              </span>
          </a>
        </div>
        <div class="col-3 col-md-1 text-center">
          <a
              href   = "http://www.shilohcabinetry.com"
              target = "_blank"
          >
            <img
                src   = "../assets/media/logos/ShilohLogoGrey.png"
                class = "img-fluid" alt="Shiloh Cabinetry logo"
            >
          </a>
        </div>
        <div class="col-3 col-md-1 text-center">
          <a
              href   = "http://eclipsecabinetry.com/"
              target = "_blank"
          >
            <img
                src   = "../assets/media/logos/EclipseLogoGrey.png"
                class = "img-fluid"
                alt   = "Shiloh Cabinetry logo"
            ></a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'pagefooter'
});
</script>

<style scoped>

</style>
