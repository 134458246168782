








































































import Vue from 'vue';

import { factory } from '@/logging';

import Navbar from '@/components/navbar.vue';

const logger = factory.getLogger('component.page404');

export default Vue.extend({
  name:       'page404',
  props:      { },
  components: { Navbar },
  created(): void {
    logger.trace('404 component has been created.');
  },
  methods:  { },
  computed: { },
  data() {
    return {
      navbar: ''
    };
  },
});
