

































































































import Vue        from 'vue';
import PageHeader from '@/components/products/pageheader.vue';

import { factory      } from '@/logging';
import { AzureStorage } from '@wwinc/azure-storage/azure-storage';


const logger = factory.getLogger('component.product');

export default Vue.extend({
  name:       'products',
  components: {
    PageHeader
  },
  data() {
    return {
      navbar: 'products'
    };
  },
  computed: {
    woodTypesImg() {
      return AzureStorage.config.storageAccountUrl + "aspect-images-products/WoodSpeciesButton.png";
    },
    rangeHoodsImg() {
      return AzureStorage.config.storageAccountUrl + "aspect-images-products/RangeHoodButton.jpg";
    },
    doorStyleImg() {
      return AzureStorage.config.storageAccountUrl + "aspect-images-products/DoorStyleButton.png";
    },
    finishesImg() {
      return AzureStorage.config.storageAccountUrl + "aspect-images-products/FinishButton.png";
    },

  },
  created(): void {
  },
  methods: {}
});
