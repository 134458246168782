




























































































import Vue                  from 'vue';
import PageHeader           from '@/components/products/pageheader.vue';
import { AzureStorageBlob } from "@wwinc/azure-storage";

enum WoodSpecies {
  Maple          = "Maple",
  AmericanPoplar = "American Poplar",
  RedOak         = "Red Oak",
}

interface Data {
  WoodSpecies:          any,
  selectedSpecies:      WoodSpecies,
  selectedImage:        AzureStorageBlob | null,
  mapleImages:          AzureStorageBlob[],
  americanPoplarImages: AzureStorageBlob[],
  redOakImages:         AzureStorageBlob[],
}

export default Vue.extend({
  name: 'Stain',

  components: {
    PageHeader,
  },

  props: {
    section: String
  },

  inject: [
    '$imageService'
  ],

  data(): Data {
    return {
      WoodSpecies:          WoodSpecies,
      selectedSpecies:      WoodSpecies.Maple,
      selectedImage:        null,
      mapleImages:          [],
      americanPoplarImages: [],
      redOakImages:         [],
    };
  },

  methods: {
    /**
     * Returns the stains for a passed in wood species
     * @param species - A WoodSpecies enum value
     */
    stainsToDisplay(species: WoodSpecies): AzureStorageBlob[] {
      // Helper function to filter out all images that are glazes
      function filterOutGlaze(images: AzureStorageBlob[]): AzureStorageBlob[] {
        return images.filter((stain) => !stain.Name.includes('glaze'));
      }

      switch (species) {
        case WoodSpecies.Maple:          return filterOutGlaze(this.mapleImages);
        case WoodSpecies.AmericanPoplar: return filterOutGlaze(this.americanPoplarImages);
        case WoodSpecies.RedOak:         return filterOutGlaze(this.redOakImages);
        default:
          console.error("DEFAULT CASE SHOULDN'T BE TRIGGERED");
          return filterOutGlaze(this.mapleImages);
      }
    },


    // Changes the selected image to display
    changeSelectedImage (image: AzureStorageBlob | null) {
      if (image != null) {
        this.selectedImage = image;
      }
    },

    // Changes the selected Wood Species
    changeSpecies(newSpecies: WoodSpecies): void {
      this.selectedSpecies = newSpecies;
      this.selectedImage   = this.stainsToDisplay(newSpecies)[0];
    }
  },

  beforeMount() {
    // Cleans all the metadata to ensure none of the values are undefined/null
    const cleanMetadata = (image: AzureStorageBlob) => {
      if (image.Metadata == undefined || image.Metadata == "") image.Metadata = {};

      if (image.Url                   == undefined) image.Url                   = "";
      if (image.Name                  == undefined) image.Name                  = "";
      if (image.Metadata.finish       == undefined) image.Metadata.finish       = "None";
      if (image.Metadata.finish_type  == undefined) image.Metadata.finish_type  = "None";
      if (image.Metadata.cabinet      == undefined) image.Metadata.cabinet      = "None";
      if (image.Metadata.door         == undefined) image.Metadata.door         = "None";
      if (image.Metadata.glaze        == undefined) image.Metadata.glaze        = "None";
      if (image.Metadata.html         == undefined) image.Metadata.html         = "";
      if (image.Metadata.other        == undefined) image.Metadata.other        = "None";
      if (image.Metadata.aged         == undefined) image.Metadata.aged         = false;
      if (image.Metadata.species      == undefined) image.Metadata.species      = "None";
      if (image.Metadata.shade        == undefined) image.Metadata.shade        = "None";
      if (image.Metadata.thumbnail    == undefined) image.Metadata.thumbnail    = "None";
      if (image.Metadata.type         == undefined) image.Metadata.type         = 0;
      if (image.Metadata.display_name == undefined) image.Metadata.display_name = "";
      if (image.Metadata.upload_date  == undefined) image.Metadata.upload_date  = "None";
    }

    // alphabetically sorts an array of AzureStorageBlobs (images) by finish name
    const imageAlphabeticalSort =
      (imageA: AzureStorageBlob, imageB: AzureStorageBlob) =>
        imageA.Metadata.finish.localeCompare(imageB.Metadata.finish);

    // Fills this.mapleImages with all the image options, ignore retired colors and dupes
    (Vue as any)
      .$imageService
      .getImages('aspect-products-finishes-stain')
      .subscribe((images: AzureStorageBlob[]) => {
        images.map(cleanMetadata);

        const maple = images.filter( (image: AzureStorageBlob) =>
          image.Metadata             !=   undefined &&
          image.Metadata.finish      !=   undefined &&
          image.Metadata.finish      !== 'None'     &&
          image.Metadata.species     === 'Maple'    &&
          image.Metadata.glaze       === 'None'
        );

        this.mapleImages = maple.sort(imageAlphabeticalSort);
        // maple is set on create because it's the default to show
        this.changeSpecies(WoodSpecies.Maple);
      });

    // Fills this.americanPoplarImages with all the image options, ignore retired colors and dupes
    (Vue as any)
      .$imageService
      .getImages('aspect-products-finishes-stain')
      .subscribe( (images: AzureStorageBlob[]) => {
        images.map(cleanMetadata);

        const amPop = images.filter( (image: AzureStorageBlob) =>
          image.Metadata             !=   undefined        &&
          image.Metadata.finish      !=   undefined        &&
          image.Metadata.finish      !== 'None'            &&
          image.Metadata.species     === 'American Poplar' &&
          image.Metadata.glaze       === 'None'
        );

        this.americanPoplarImages = amPop.sort(imageAlphabeticalSort);
      });

    // Fills this.redOakImages with all the image options, ignore dupes
    (Vue as any)
      .$imageService
      .getImages('aspect-products-finishes-stain')
      .subscribe( (images: AzureStorageBlob[]) => {
        images.map(cleanMetadata);

        const redOak = images.filter( (image: AzureStorageBlob) =>
          image.Metadata             !=   undefined &&
          image.Metadata.finish      !=   undefined &&
          image.Metadata.finish      !== 'None'     &&
          image.Metadata.species     === 'Red Oak'  &&
          image.Metadata.glaze       === 'None'
        );

        this.redOakImages = redOak.sort(imageAlphabeticalSort);
      });
  },
});
