









































































































import Vue       from 'vue';

import { AzureStorage } from '@wwinc/azure-storage';
import { factory      } from '@/logging';

import store from '@/store';

import PageHeader from '@/components/products/pageheader.vue';

const logger = factory.getLogger('component.main');

export default Vue.extend({
  name: 'home',
  props: { },
  components: {
    PageHeader
  },
  created(): void {
    logger.debug('Main component has been created.');
    store.dispatch('homeImage/loadImages');
  },
  destroyed() {
    clearInterval(this.timer);
  },
  methods: { },
   mounted() {
    this.timer = setInterval(()=>{
      store.dispatch('homeImage/switchImages');
    }, this.mosaicInterval);
   },
  computed: {
    mode() {
      return process.env.VUE_APP_MODE;
    },
    selectedRect() {
      return store.getters['homeImage/selectedRectImages'];
    },
    selectedSqr() {
      return store.getters['homeImage/selectedSqrImages'];
    },
    selectedWid() {
      return store.getters['homeImage/selectedWideImages'];
    },
    selectedReg() {
      return store.getters['homeImage/selectedRegImages'];
    },
    sqr() {
      return store.getters['homeImage/sqrImages'];
    },
    loading() {
      return store.getters['homeImage/loading'];
    }
  },
  data() {
    return {
      navbar:         'home',
      message:        'Built with a Better Perspective',
      azureStorage:   undefined  as AzureStorage | undefined,
      mosaicInterval: 5500, //in milliseconds
      timer:          undefined as any //set timer to activate interval for images
    };
  },
  inject: [
      '$imageService'
  ],
});
