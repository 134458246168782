








































































































































































































































































































































import Vue from 'vue';
import $   from 'jquery';

import { factory } from '@/logging';
import PageHeader from '@/components/products/pageheader.vue';

const logger = factory.getLogger('component.about');

export default Vue.extend({
  name: 'about',
  props: { },
  components: {
    PageHeader
  },
  created(): void { },
  methods: {
    /**
     * This function will mark a tab as being inactive.
     * @param tabName The name of the tab to mark.
     */
    unshowTab: (tabName: string) => {
      $(`#pills-${tabName}-tab`).removeClass('active');
      const tab = $(`#${tabName}`).removeClass(['active show']);
    },
    /**
     * Hide all tabs before showing the active tab.
     * @param section The tab to smake active.
     */
    selectionChanged: function (section: string) {
      this.unshowTab('intro');
      this.unshowTab('construction');
      this.unshowTab('env-impact');
      this.unshowTab('care');

      this.showTab(section);
    },

    /**
     * Make the specified tab the 'active' tab.
     * @param tabName The tab to make active.
     */
    showTab: (tabName: string) => {
      $(`#pills-${tabName}-tab`).addClass('active');
      $(`#${tabName}`).addClass(['active', 'show']);
    }
  },
  computed: { },
  data() {
    return {
      navbar: 'about'
    };
  },
});
