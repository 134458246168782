


































































































































































































































































































































































































































































































































































































import PageHeader          from '@/components/products/pageheader.vue';
import { factory       }   from '@/logging';
import { RetailersForm }   from "@wwinc/email/models/retailers-form";
import { useVuelidate  }   from "@vuelidate/core";
import { defineComponent } from '@vue/composition-api'
import {
  countries,
  State
  } from '@/services/retailers.service';

import {
  required,
  requiredIf,
  email,
  alpha,
  minLength,
  maxLength,
  numeric,
  helpers,
} from "@vuelidate/validators";

const logger = factory.getLogger('component.retailers');


interface Countries {
  availableOptions: any,
  selectedOption: {
    name: string,
    code: string,
  },
}

enum FormType {
  LocateRetailer = 1,
  BecomeRetailer = 0,
  None           = -1
}

enum ProjectType {
  Both            = "Both",
  Remodel         = "Remodel",
  NewConstruction = "New Construction"
}

interface CommentInfo {
  comments:    string,
  describe:    string,
  projectType: ProjectType
}

interface ContactInfo {
  phone:        string
  businessName: string,
  phoneExt:     string
}

interface RetailerForm {
  email:            string,
  firstName:        string,
  lastName:         string,
  streetAddress:    string,
  city:             string,
  state:            State | '',
  zip:              string,
}

interface Data {
  ProjectType:      any,  // Allows enum accessible in Component
  FormType:         any,
  State:            any,
  mask:             string,
  errorMessage:     null | string,
  working:          boolean,
  formType:         FormType,
  success:          boolean,
  countries:        Countries,
  retailersForm:    RetailerForm,
  comment:          CommentInfo,
  contact:          ContactInfo,
  distributionArea: number,
}

export default defineComponent({
  name: 'Retailers',

  components: {
    PageHeader,
  },

  props: {
    section: String
  },

  inject: ['$retailersService'],

  setup () {
    return {
      v$: useVuelidate(),
    }
  },

  data(): Data {
    return {
      ProjectType,  // Allows enum accessible in Component
      FormType,
      State,
      mask:         '(###) ###-####',
      errorMessage: null,
      working:      false,
      formType:     FormType.None,
      success:      false,

      retailersForm: {
        email:         '',
        lastName:      '',
        firstName:     '',
        streetAddress: '',
        city:          '',
        state:         '',
        zip:           ''
      },

      contact: {
        phone:        '',
        businessName: '',
        phoneExt:     '',
      },

      comment: {
        comments:    '',
        describe:    '',
        projectType: ProjectType.Remodel
      },

      countries: {
        availableOptions: countries,
        selectedOption: {
          name: 'United States',
          code: 'US'
        }
      },

      distributionArea: 1,
    };
  },

  validations() {
    return {
      retailersForm: {
        email: {
          required: helpers.withMessage('An email is required', required),
          email:    helpers.withMessage('Enter a valid email',  email),
        },
        firstName: {
          required: helpers.withMessage('First name is required', required),
          alpha:    helpers.withMessage('Enter letters only',     alpha),
        },
        lastName: {
          required: helpers.withMessage('Last name is required', required),
          alpha:    helpers.withMessage('Enter letters only',    alpha),
        },
        streetAddress: {
          required: helpers.withMessage('Street address is required', required),
        },
        city: {
          required: helpers.withMessage('City is required',   required),
        },
        state: {
          required: helpers.withMessage('State is required',  required),
          alpha:    helpers.withMessage('Enter letters only', alpha),
        },
        zip: {
          required:  helpers.withMessage('Zipcode is required',   required),
          numeric:   helpers.withMessage('Enter numbers only',    numeric),
          maxLength: helpers.withMessage('Enter a valid zipcode', maxLength(5)),
          minLength: helpers.withMessage('Enter a valid zipcode', minLength(5)),
        },
      },
      contact: {
        phone: {
          required:  helpers.withMessage('Phone number is required',   required),
          maxLength: helpers.withMessage('Enter a valid phone number', maxLength(14)),
          minLength: helpers.withMessage('Enter a valid phone number', minLength(14)),
        },
        businessName: {
          // Only requires business name on the become a retailer page
          required: helpers.withMessage( "Business Name is required",
            requiredIf(this.formType === FormType.BecomeRetailer)
          ),
        },
        phoneExt: {
          numeric: helpers.withMessage('Enter numbers only', numeric),
        }
      },
    };
  },

  computed: {
    submitEnabled() {
      return true
    },

    /**
     * @name getStates
     * @description - Converts a full Enum type into an array useable by Vuetify
     * @returns - An array of objects that contain key/value pairs for each state
     */
    getStates() {
      const states = [];
      for (const [key, value] of Object.entries(State)) {
        states.push(
          {
            key:   key,
            value: value,
          }
        );
      }
      return states;
    },
  },

  created(): void {
    logger.trace('Retailers component has been created.');
  },

  methods: {
    resetForm() {
      this.retailersForm = {
        email:         '',
        lastName:      '',
        firstName:     '',
        streetAddress: '',
        city:          '',
        state:         '',
        zip:           ''
      },

      this.contact = {
        phone:        '',
        businessName: '',
        phoneExt:     '',
      },

      this.comment = {
        comments:    '',
        describe:    '',
        projectType: ProjectType.Remodel
      },

      this.countries.selectedOption = {
        name: 'United States',
        code: 'US'
      }

      // Resets form validation to clean state
      this.v$.$reset();
    },

    /**
     *
     * Handles the submit button click by attempting to send emails.
     * @param event
     */
    async submitRequest() {
      // Only submits data if it is valid
      if (!await this.v$.$validate()) {
        this.errorMessage = "A field is invalid";
        return;
      }

      if (this.success) {
        this.errorMessage = "You have already submitted a request";
        return;
      }

      logger.debug('OnSubmit has been called');
      this.errorMessage = null;

      const formData: RetailersForm = {
        retailer:         this.formType,
        firstName:        this.retailersForm.firstName,
        lastName:         this.retailersForm.lastName,
        email:            this.retailersForm.email,
        contact:          this.contact,
        streetAddress:    this.retailersForm.streetAddress,
        city:             this.retailersForm.city,
        state:            this.retailersForm.state,
        zip:              this.retailersForm.zip,
        country:          this.countries.selectedOption.name,
        projectType:      this.comment.projectType,
        describe:         this.comment.describe,
        comments:         this.comment.comments,
        distributionArea: this.distributionArea
      };

      (this as any).$retailersService.sendEmails(formData).then(
        (response: any) => {
          console.log(response)
          if (response == true) {
            this.success = true;
            this.resetForm();
          } else {
            this.errorMessage = 'This form could not be submitted. Please try again after a few moments.'
          }
        }
      )

      // (this as any).$retailersService.sendEmails(formData).subscribe(
      //     (resp: any) => {
      //       console.log(resp)
      //       if (resp == true) {
      //         this.success = true;
      //         (this as any).resetForm();
      //       } else {
      //         this.error = true;
      //         this.errorMessage = 'This form could not be submitted. Please try again after a few moments.'
      //       }
      //     }
      // );

      // (this as any).$retailersService.EmailRetailersForm(
      //     formData
      // ).then((resp: any) => {
      //     this.success = !!resp;
      //     if (this.success) {
      //       (this as any).resetForm();
      //     } else {
      //       this.error = true;
      //       this.errorMessage = 'This form could not be submitted. Please try again after a few moments.';
      //     }
      // });
    }
  },
});
