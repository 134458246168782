





















































import Vue         from 'vue';
import { factory } from '@/logging';

import { AzureStorageBlob } from '@wwinc/azure-storage';
import PageHeader           from '@/components/products/pageheader.vue';
import Resource             from '@/models/resource.model';


const logger = factory.getLogger('component.resources');

export default Vue.extend({
  inject:     [
    '$resourcesService'
  ],
  name:       'resources',
  props:      {
    section: String
  },
  components: {
    PageHeader
  },
  created(): void {
    logger.trace('Resources component has been created.');
  },
  methods:  {},
  mounted:  function () {
    logger.debug('Getting images from container aspect-documents-resources');

    this.$resourcesService.getResources().subscribe(
      (resources: Resource[]) => {
        this.downloads = resources;
        return this.downloads;
      }
    );
  },
  computed: {},
  data() {
    return {
      //set runtime variables
      navbar:    'resources',
      downloads: [] as AzureStorageBlob[]
    };
  }
} as any);
