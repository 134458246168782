



























































import { factory } from '@/logging';

import Vue    from 'vue';
import PageHeader from '@/components/products/pageheader.vue';
import PageFooter from '@/components/pagefooter.vue';
import {AzureStorageBlob} from "@wwinc/azure-storage";
const logger = factory.getLogger('component.product.doorstyle');
import LightBox from '../standalone/lightbox.vue';

export default Vue.extend({
  name: 'products',
  props: {
    section: String
  },
  components: {
    PageHeader,
    PageFooter,
    LightBox
  },
  created(): void {
    logger.trace('DoorStyles component has been created.');
  },
  methods:  {


    changeIndex(index: number) {
      this.index = index;
    },
    imageClicked(index: number) {
      this.index = index;
      this.visible = true;
    },
    getHeader(image: AzureStorageBlob) {
      return image.Metadata.door;
    },
    getFooter (image: AzureStorageBlob) {
      return image.Metadata.html;
    },

    getUrl(image: AzureStorageBlob)  {
      return image.Url;
    },
    getImageIndex() {
      return this.index;
    },
    getImages() {
      return (this as any).$data.doorStyleImages;
    },
    OnClose() {
      this.visible = false;
    },
    getAlt(image: any) {
      return image.alt;
    },

  },
  computed: { },
  data() {
    return {
      index: 0,
      visible: false,
      navbar: 'products',
          doorstyleImages: [] as Array<any>
    };
  },
  mounted() {
    logger.debug(
        'Getting images from container aspect-images-products-doorstyles'
    );

    (this as any).$imageService!.getImages(
        'aspect-images-products-doorstyles'
    ).subscribe((data: any)=>{
      logger.trace(
          `aspect-images-products-doorstyles: ${JSON.stringify(data, null, 2)}`
      );

      this.doorstyleImages = data;
    });
  },
  inject: ['$imageService']
});
