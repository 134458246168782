import Vue    from 'vue';
import router from './router';
import store  from './store';
import App    from './App.vue';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';


import './assets/css/animate.css';
import './assets/css/font-awesome.css';
import '../node_modules/material-design-icons-iconfont/dist/material-design-icons.css';
import './assets/css/style.css';

const VueMask = require('v-mask');

import { ResourcesService } from '@/services/resources.service';
import { ImageService     } from '@/services/image.service';
import { factory          } from '@/logging';
import { RetailersService } from '@/services/retailers.service';

import AppConfig         from '@/AppConfig';
import VueGtag           from 'vue-gtag';
import VueCompositionAPI from '@vue/composition-api'

Vue.config.productionTip = false;

const appConfig: AppConfig = {
  mode:              process.env.VUE_APP_MODE,
  containersUrl:     process.env.VUE_APP_CONTAINERS_URL,
  companyEmail:      process.env.VUE_APP_COMPANY_EMAIL,
  commonApiEndpoint: process.env.VUE_APP_COMMON_API_ENDPOINT,
  emailApiEndpoint:  process.env.VUE_APP_EMAIL_API_ENDPOINT
}

const $resourcesService = new ResourcesService(appConfig);
const $imageService     = new ImageService(appConfig);
const $retailersService = new RetailersService(appConfig);

(Vue as any).$imageService = $imageService;

const logger = factory.getLogger('main');

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || 'Aspect Cabinetry';
  })
});

Vue.use(VueMask.VueMaskPlugin);
Vue.use(VueCompositionAPI);

Vue.use(VueGtag, {
    config: {
        id: 'UA-72870914-9'
    },
}, router);

new Vue(
  {
    router,
    store,
    provide: {
      $resourcesService,
      $imageService,
      $retailersService,
    },
    created(): void {
      logger.debug('Created new Vue instance');
    },
    render: (h) => h(App)
  }
).$mount(
  '#app'
);
