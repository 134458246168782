









































































import Vue from 'vue';

import { factory } from '@/logging';

import PageHeader from '@/components/products/pageheader.vue';
import PageFooter from '@/components/pagefooter.vue';
import { AzureStorage } from '@wwinc/azure-storage/azure-storage';


const logger = factory.getLogger('component.product.finish');

export default Vue.extend({
  name:       'finishes',

  props:      {
    section: String
  },

  components: {
    PageHeader,
    PageFooter
  },

  data() {
    return {
      navbar: 'products'
    };
  },

  created(): void {
  },

  computed: {
    paintImg() {
      return AzureStorage.config.storageAccountUrl + "aspect-images-products/PaintsButton.jpg"
    },
    stainImg() {
      return AzureStorage.config.storageAccountUrl + "aspect-images-products/StainsButton.jpg"
    },
  },

  methods: {},
});
