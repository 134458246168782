<template>
  <header class="fixed-top navbar-light bg-light">
    <div
      id="top-logo"
      class="d-none d-md-block"
    >
      <router-link to="/">
        <img
          alt="Aspect Cabinetry"
          src="@/assets/media/logos/Aspect.png"
        >
      </router-link>
    </div>
    <Navbar :section="section" />
  </header>
</template>

<script>

import Navbar from '@/components/navbar';


export default {
  name:       'pageheader',
  props:      {
    section: String
  },
  components: {
    Navbar
  }
};
</script>

<style scoped>

</style>
